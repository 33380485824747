import automatic from '@assets/svg/illustration-automatic.svg'
import live from '@assets/svg/illustration-live.svg'
import noPaper from '@assets/svg/illustration-nopaper.svg'

const mission = [
    {
        id: 1,
        title: 'Dématérialisation',
        content: [
            "Les plannings papier, c'est has been ! L'heure est à la dématérialisation. Profitez de tous les avantages du logiciel Staffea, sur ordinateur et sur smartphone. Gérez vos plannings en un clic, ajoutez vos salariés dans l'outil de gestion et simplifiez vos démarches de recrutement avec les modèles de contrat et la signature électronique.",
        ],
        svg: noPaper,
    },
    {
        id: 2,
        title: 'Gestion en temps réel',
        content: [
            "Vous en avez assez de mettre à jour manuellement vos plannings ? Avec Staffea, c'est terminé : le logiciel de gestion d'horaires intègre toutes les modifications en temps réel : absences, remplacements, heures supplémentaires, etc.",
            "Vos employés accèdent à leur planning depuis l'application et peuvent faire des demandes d'ajustements, vous donner leurs disponibilités ou accepter des heures supplémentaires.",
        ],
        svg: live,
    },
    {
        id: 3,
        title: 'Automatisation',
        content: [
            "Grâce à l'intelligence artificielle et au machine learning, Staffea est un logiciel de planning intelligent et flexible, parfait pour vous faire gagner du temps. L'outil prend en compte toutes les modifications et les intègre dans le planning de façon automatique.",
            "Besoin d'un remplaçant suite à un arrêt maladie ? Staffea recherche automatiquement les meilleurs remplaçants, selon leurs disponibilités. Une fois la mission acceptée, le planning se met à jour en temps réel puis calcule les éléments variables de paie.",
        ],
        svg: automatic,
    },
]

export default mission
