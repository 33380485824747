import imgMobileExchange from '@assets/images/mobile-exchange.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'
import pictoCalcul from '@assets/svg/picto-handle-calcul.svg'
import pictoHours from '@assets/svg/picto-hours-auto.svg'
import pictoLegal from '@assets/svg/picto-legal-constraints.svg'
import pictoPlanning from '@assets/svg/picto-planning.svg'
import pictoTransfert from '@assets/svg/picto-transfert.svg'

const functionalities = [
    {
        id: 1,
        subTitle: 'Des plannings en 2H',
        title: 'Création des horaires quotidiens',
        image: pictoPlanning,
        list: [
            "Appui sur l'intelligence artificielle pour construire les plannings",
            "Création d'horaires types à attribuer à une ou plusieurs personnes",
            "Alertes de non-conformité en cas d'anomalie sur le planning",
        ],
    },
    {
        id: 2,
        subTitle: "Suggestions grâce à l'IA",
        title: 'Calcul automatique des horaires',
        image: pictoHours,
        list: [
            "Suggestion d'horaires en fonction de vos besoins en personnel",
            'Utilisation du système de pointage pour ajuster le planning',
            "Vérification de la planification selon vos accords d'entreprise ou votre convention collective",
        ],
    },
    {
        id: 3,
        subTitle: 'Un planning agile',
        title: 'Ajustement des calculs des horaires',
        image: pictoCalcul,
        list: [
            'Ajout automatique des modifications selon les absences et les remplacements',
            'Prise en compte des préférences des collaborateurs',
            'Envoi de notifications au personnel à chaque modification de planning',
        ],
    },
    {
        id: 4,
        subTitle: 'Des remplacements faciles',
        title: 'Gestion des remplacements',
        image: pictoTransfert,
        list: [
            "Indiquez les créneaux à pourvoir sur l'application Staffea",
            'Interrogez les potentiels remplaçants habilités de votre réseau',
            'Dématérialisez le recrutement avec la signature électronique des contrats',
        ],
    },
    {
        id: 5,
        subTitle: 'Un planning toujours conforme',
        title: 'Alertes réglementaires',
        image: pictoLegal,
        list: [
            'Staffea vérifie automatiquement que les roulements sont conformes à la loi',
            "Recevez une alerte en cas de non-conformité du planning avec vos accords d'entreprise ou votre convention collective",
            'Vérifiez que votre planification correspond à vos besoins en personnel',
        ],
    },
    {
        id: 6,
        subTitle: 'Un planning de poche agile',
        title: 'Application mobile de planning',
        images: [
            { image: imgMobilePlanning, alt: 'Staffea, application mobile consultation du planning' },
            { image: imgMobileExchange, alt: 'Staffea, application mobile échange de jours entre collaborateurs' },
        ],
        list: [
            "Vos salariés vérifient leurs horaires en un clin d'oeil",
            "Demande d'échange d'horaires avec leurs collègues",
            "Déclaration d'heures supplémentaires, automatiquement calculées",
        ],
    },
]

export default functionalities
