import imgHR from '@assets/images/img-HR.png'
import imgUser from '@assets/images/img-handle-all-optimize-experience.png'
import imgTransfer from '@assets/images/img-less-admin-transfert.png'
import imgTsqPlanning from '@assets/images/img-tailor-intelligent-planning.png'

const content = [
    {
        id: 1,
        header: 'Créez des plannings sur mesure',
        title: 'Avec Staffea, créez des horaires types !',
        text: "L'IA et le machine learning intégrés dans notre logiciel de planning d'horaires vous permet d'attribuer automatiquement des roulements à vos salariés et de construire vos plannings en 2H. Staffea prend en compte les préférences de vos collaborateurs. Connectez votre logiciel métier pour inclure vos besoins en personnel.",
        image: imgTsqPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
    },
    {
        id: 2,
        header: 'Gérez vos plannings en temps réel',
        title: 'Apportez des modifications à tout moment.',
        text: "Grâce à ses mises à jour en temps réel, Staffea facilite votre gestion de planning. La prise en main est également rapide et facile pour vos salariés. Recevez immédiatement les arrêts maladies sur l'application pour anticiper et gérer les remplacements. Publiez des créneaux à pourvoir sur Staffea auprès des personnes habilitées dans votre réseau.",
        image: imgTransfer,
        alt: 'Staffea, application web écran de gestion des remplacements',
    },
    {
        id: 3,
        header: 'Sécurisez vos dispositifs RH',
        title: 'Assurez-vous de la conformité de vos plannings !',
        text: "Notre logiciel de planning d'horaires du personnel contrôle la conformité réglementaire de vos plannings, selon votre secteur. Le respect du RGPD est assuré puisque notre solution française est indépendante des GAFAM. Staffea édite automatiquement vos contrats de travail et valide les heures travaillées par vos équipes.",
        image: imgHR,
        alt: 'Staffea, application web écran de gestion des contrats employés',
    },
    {
        id: 4,
        header: "Optimisez l'expérience collaborateur",
        title: 'Fidélisez vos collaborateurs grâce à un outil vraiment utile.',
        text: "Staffea dispose d'une application mobile pour être disponible à tout moment. Elle facilite les échanges d'horaires entre les membres de l'équipe, les modifications de planning et permet d'envoyer des arrêts maladies dématérialisés en un clic. L'application comprend un espace de feedback pour que les équipes terrains partagent leur expérience.",
        image: imgUser,
        alt: 'Staffea, application mobile multifonctionnelle',
    },
]

export default content
