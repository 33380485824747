const specificities = [
    {
        id: 1,
        color: 'var(--chakra-colors-green-500)',
        name: 'Santé',
        description:
            "Améliorez le bien-être au travail de votre personnel de santé en utilisant Staffea. Diminuez leur charge mentale pour qu'ils se concentrent uniquement sur la qualité de l'accompagnement et du soin. Gérez facilement les plannings grâce à l'intelligence artificielle !",
        fields: ['Ehpad', 'hôpitaux', 'cliniques', 'soins à domicile', 'laboratoire'],
    },
    {
        id: 2,
        color: 'var(--chakra-colors-yellow)',
        name: 'Services',
        description:
            "Appuyez-vous sur ce logiciel de calcul d'horaires de travail pour optimiser votre gestion administrative et RH. En un clic, validez les demandes de remplacement, de congés ou d'ajustement d'horaires !",
        fields: ['Restauration', 'hôtellerie', 'distribution', 'sécurité', "centres d'appel"],
    },
    {
        id: 3,
        color: 'var(--chakra-colors-orange)',
        name: 'Industrie',
        description:
            "La gestion des plannings de chantier n'a jamais été aussi facile qu'avec Staffea. Créez des plages horaires types en fonction de vos besoins de production. Le calcul des heures de travail est automatique et directement ajouté à votre logiciel de paie.",
        fields: ['Production industrielle', 'logistique', 'génie civil'],
    },
]

export default specificities
